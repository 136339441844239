import React from 'react'
import { Link, graphql } from 'gatsby'
import {
  makeStyles,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  media: {
    height: '100%',
    width: '100%',
  },
  card: {
    // width: 337,
    // height: 500,
  },
})

interface Props {
  id: any
  slug: string
  image: string
  name: string
  price: string | number
}

export const ProductCard: React.FC<Props> = ({
  id,
  slug,
  image,
  name,
  price,
}) => {
  const classes = useStyles()

  const [elevation, updateElevation] = React.useState(4)

  const handleOnMouseOver = () => updateElevation(15)
  const handleOnMouseOut = () => updateElevation(4)
  return (
    <Card
      elevation={elevation}
      className={classes.card}
      onMouseOver={handleOnMouseOver}
      onMouseOut={handleOnMouseOut}
    >
      <CardActionArea>
        {/* <CardMedia
            className={classes.media}
            title={id}
            // image={data.image.fixed.src}
            src={image}
            image={image}
          /> */}
        <Link to={`/${slug}`}>
          <img src={image} alt={name} className={classes.media} />
        </Link>
        <Link to={`/${slug}`}>
          <CardContent>
            <Typography
              align={'center'}
              gutterBottom
              display={'block'}
              variant={'h3'}
            >
              {name}
            </Typography>
            <Typography align={'center'} display={'block'} variant={'h5'}>
              ${price}
            </Typography>
          </CardContent>
        </Link>
      </CardActionArea>
    </Card>
  )
}
