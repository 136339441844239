import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Layout } from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'
import { LatestBlogs } from '../components/LatestBlog'

import { ProductCard } from '../components/ProductCard'
import { Grid, Typography } from '@material-ui/core'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export interface IndexProps {
  data: any
}

const IndexPost: React.FC<IndexProps> = ({ data }) => {
  const [contentfulProducts, updateContentfulProducts] = React.useState(
    data.data.allContentfulProduct.edges
  )

  // Parse the URL parameter
  const getParameterByName = (name: string, url?: string) => {
    if (!url) url = window.location.href
    name = name.replace(/[\[\]]/g, '\\$&')
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  React.useEffect(() => {
    console.log(getParameterByName('success'))
    const response = getParameterByName('success')
    if (response === 'booking') {
      toast('💫 Booking Request Sent 💫')
    }

    if (response === 'purchase') {
      toast('💫 Checkout was Successful 💫')
    }
  }, [])

  React.useEffect(() => {
    console.log(
      'data.allContentfulProduct',
      data.data.allContentfulProduct.edges
    )
    updateContentfulProducts(data.data.allContentfulProduct.edges)
  }, [data])

  return (
    <Grid container spacing={4} style={{ paddingBottom: 50 }}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {contentfulProducts.map((p: any) => (
        <Grid key={p.node.id} item md={4}>
          <ProductCard
            id={p.node.id}
            slug={p.node.slug}
            image={p.node.image.fixed.src}
            name={p.node.name}
            price={p.node.price}
          />
        </Grid>
      ))}
    </Grid>
  )
}

const IndexPage = (data: any) => (
  <Layout>
    <SEO
      title="Home"
      keywords={[`film`, `videography`, `photography`, `music video`]}
    />
    <Banner BannerData={data.data.allContentfulHeaderBanner.edges} />
    <LatestBlogs data={data.data.allContentfulBlogs} />
    <div className="container">
      <div className="text-center">
        <Typography gutterBottom variant={'h1'} className="with-underline">
          Latest Items
        </Typography>
      </div>
      <IndexPost data={data}></IndexPost>
    </div>
    {/* TODO: fix featureImage property not showing in query */}
    {/* <Countdown data={data.data.contentfulDealCountDown} /> */}
  </Layout>
)

export default IndexPage

export const query = graphql`
  query AboutQuery {
    allContentfulProduct(limit: 3, sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          name
          slug
          image {
            fixed(width: 1200, height: 1200) {
              width
              height
              src
              srcSet
            }
          }
          price
          details {
            childMarkdownRemark {
              excerpt(pruneLength: 140)
            }
          }
        }
      }
    }
    allContentfulHeaderBanner {
      edges {
        node {
          title
          subHeading
          image {
            fluid(maxWidth: 1800) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
    contentfulDealCountDown {
      title

      date(formatString: "D MMMM, YYYY")
    }
    allContentfulBlogs(limit: 3, sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          title
          slug
          featureImage {
            fluid(maxWidth: 1120) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  }
`
