import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { Typography } from '@material-ui/core'

interface Props {
  data: any
}

export const LatestBlogs: React.FC<Props> = ({ data }) => {
  console.log('data', data)
  return (
    <div className="container">
      <div className="text-center">
        <Typography gutterBottom variant={'h1'} className="with-underline">
          Multimedia & Film Production
        </Typography>
      </div>
      <ul className="latest-blog">
        {data.edges.map((items: any, index: number) => {
          console.log('items', items)
          return (
            <li key={items.node.id}>
              <div className="inner">
                <Link to={items.node.slug}></Link>
                <Img sizes={items.node.featureImage.fluid} />
                <h2>{items.node.title}</h2>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
