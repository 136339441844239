import React from 'react'
import Slider from 'react-slick'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { Colors } from '../theme/Colors'
import { Typography } from '@material-ui/core'

export interface BannerProps {
  BannerData: any
}

const settings = {
  dots: true,
  speed: 500,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
}

export default class Banner extends React.Component<BannerProps> {
  render() {
    const { BannerData } = this.props

    return (
      <div className="slider-section">
        <Slider {...settings}>
          {BannerData.map((items: any, i: number) => (
            <div key={i} className="item">
              <div className="site-Banner">
                <Img sizes={items.node.image.fluid} />
                <div className="Banner-details">
                  <div>
                    <Typography
                      gutterBottom
                      variant={'caption'}
                      className="sub-title"
                      display={'block'}
                      style={{ marginBottom: '16px' }}
                    >
                      {items.node.subHeading}
                    </Typography>
                    <Typography gutterBottom variant={'h1'}>
                      {items.node.title}
                    </Typography>
                    <Link
                      style={{ background: Colors.green[100] }}
                      to="/booking"
                    >
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}
